// Loader.js
import React from 'react';
import { Circles } from 'react-loader-spinner';

const Loader = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', weight: '100vw',position:"fixed", inset:0, backgroundColor: 'rgba(255, 255, 255, 0.8)',zIndex: 9999 }}>
    <Circles color="#00BFFF" height={80} width={80} />
  </div>
);

export default Loader;
