import React from 'react';
import './Text.css'; // Assuming you have a CSS file for styles

const SentimentAnalysisTool = () => {
    return (
        <div className="sentiment-analysis-container">
            <h1 className="title">Discover RedditSentiments: Your Ultimate Sentiment Analysis Tool</h1>
            <div className="content-card">
                <p className="main-text" style={{ fontStyle: "italic" }}>
                    If you use the Reddit Sentiment Analysis tool please cite it as follows:  
                    Hamelin, N., & Nguyen, A. (2024). Reddit Sentiment Analysis. SPJAIN School of Global Management. www.redditsentiments.com
                </p>
                <p className="main-text">
                    Sentiment analysis is a powerful technique used to determine the emotional tone behind a series of words. It allows individuals and organizations to gain insight into public opinions, sentiments, and trends expressed in text. By utilizing advanced natural language processing algorithms, sentiment analysis can accurately classify text as positive, negative, or neutral. This valuable information empowers marketers, researchers, and businesses to make informed decisions based on the prevailing sentiments in discussions and social media platforms.
                </p>
                <p className="main-text">
                    Unlock the power of sentiment analysis with RedditSentiments, the premier web app designed to help you gauge public opinion on Reddit with ease. Whether you’re a marketer, researcher, or simply curious about how people feel about specific topics, RedditSentiments offers a user-friendly platform that turns complex data into actionable insights. With just a few clicks, you can enter your desired keyword and watch as the app scrapes recent Reddit posts, analyzing sentiments in real-time.
                </p>
                <p className="main-text">
                    Our intuitive interface presents the results in an engaging format, featuring an informative pie chart that displays the breakdown of positive, negative, and neutral sentiments. Alongside, the dynamic word cloud highlights frequently mentioned terms, allowing you to quickly grasp the context of discussions surrounding your keyword. To provide deeper insights, the app also includes a line chart illustrating sentiment trends over time, enabling you to track how public perception evolves. No prior expertise is needed—just enter your keyword, hit submit, and let RedditSentiments do the heavy lifting for you.
                </p>
                <p className="conclusion-text">
                    Whether you want to track brand sentiment, explore trending topics, or understand consumer opinions, RedditSentiments is your go-to tool for sentiment analysis on Reddit. Experience the ease and efficiency of monitoring public sentiment today, and harness the insights that can elevate your strategies and decision-making processes. Join countless satisfied users who have discovered the benefits of real-time sentiment analysis with RedditSentiments—your gateway to understanding the pulse of online conversations!
                </p>
            </div>
        </div>
    );
};

export default SentimentAnalysisTool;
